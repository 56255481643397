<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Upon addition to water, ammonium chloride rapidly dissolves and the resulting solution is
        cold to the touch. Without performing any calculations determine the sign of
        <stemble-latex content="$\Delta\text{H,}$" />
        <stemble-latex content="$\Delta\text{S,}$" />
        and
        <stemble-latex content="$\Delta\text{G}$" />
        for this dissolution process.
      </p>

      <p class="mb-1">
        <stemble-latex content="$\text{a) }\Delta\text{H}$" />
      </p>

      <v-radio-group v-model="inputs.enthalpy" class="pl-8 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">
        <stemble-latex content="$\text{b) }\Delta\text{S}$" />
      </p>

      <v-radio-group v-model="inputs.entropy" class="pl-8 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">
        <stemble-latex content="$\text{c) }\Delta\text{G}$" />
      </p>

      <v-radio-group v-model="inputs.freeEnergy" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question302',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        enthalpy: null,
        entropy: null,
        freeEnergy: null,
      },
      options1: [
        {expression: '$\\text{Less than 0}$', value: 'neg'},
        {expression: '$\\text{Greater than 0}$', value: 'pos'},
        {
          expression: '$\\text{More information is required}$',
          value: 'unknown',
        },
      ],
    };
  },
};
</script>
